import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "../NotFound";
import { HomeWrapper } from "../Home";

export const MAIN_ROUTE_PATHS = {
  ROOT: "/",
  NOT_FOUND: "*",
};

export class MainRoutes extends Component {
  public render() {
    return (
      <Routes>
        <Route path={MAIN_ROUTE_PATHS.ROOT} element={<HomeWrapper />} />
        <Route path={MAIN_ROUTE_PATHS.NOT_FOUND} element={<NotFound />} />
      </Routes>
    );
  }
}
