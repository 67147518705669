import React, { createContext, FunctionComponent, useEffect, useState } from "react";
import { useCookieTheme } from "../../hooks/token";

interface Props {
  children?: React.ReactNode;
  defaultTheme?: AppThemes;
  setRoot?: boolean;
}

export enum AppThemes {
  DARK = "dark",
  LIGHT = "light",
}

export const ThemeContext = createContext([] as unknown as [AppThemes, React.Dispatch<React.SetStateAction<AppThemes>>]);

export const ThemeContextProvider: FunctionComponent<Props> = (props: Props) => {
  const { children, defaultTheme = AppThemes.LIGHT, setRoot } = props;
  const [cookieTheme, setCookieTheme] = useCookieTheme();
  const [theme, setTheme] = useState(cookieTheme || defaultTheme);

  useEffect(() => {
    if (theme && setRoot) {
      document.documentElement.className = theme;
      setCookieTheme(theme);
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <div className="wrapper">{children}</div>
    </ThemeContext.Provider>
  );
};
