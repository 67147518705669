export const fetchGQL = <T>(query: string, variables?: {}, token?: string): Promise<T> =>
  fetch(`https://api-application.technis.com/graphql`, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ query, variables }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then(({ data }) => data)
    .catch((e: Error) => {
      throw e;
    });
