import { getDurationToHMS } from "@technis-counting/shared";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faClock, faArrowTurnDown, faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";

import { useWaitingTime } from "../hooks/useWaitingTime";
import { RootState } from "../redux/store";
import { i18n } from "../lang/i18n";
import { translation } from "../lang/translation";
import TechnisLogo from "../../public/technis-logo.png";
import Logo from "../../public/compass-logo.png";

type Props = {
  token: string;
  zoneId: number;
  name: string;
  isTotalZone?: boolean;
  isInLeftColumn?: boolean;
};

const ZoneComponent: FunctionComponent<Props> = ({ token, zoneId, name, isInLeftColumn, isTotalZone }) => {
  const { inside, waitingTime, affluenceIn } = useWaitingTime(token, zoneId);
  const { hours, minutes, seconds } = getDurationToHMS(waitingTime || 0);

  return (
    <div className="zone">
      <div className="name-container">
        {!isTotalZone && (
          <div className={classNames("arrow-icon-container", isInLeftColumn ? "left-arrow-container" : "right-arrow-container")}>
            <FontAwesomeIcon className="arrow-icon" icon={isInLeftColumn ? faArrowTurnDown : faArrowTurnUp} />
          </div>
        )}
        <span className="name">{name}</span>
      </div>
      <div className="zone-details">
        <div className={classNames("icon-container", !isInLeftColumn && "inside-icon-container")}>
          <FontAwesomeIcon className="icon" icon={faUsers} />
        </div>
        <div className="container">
          <span className="label">
            {isTotalZone ? affluenceIn : inside} {i18n.t(translation.clients)}
          </span>
          <span className="description">{i18n.t(isTotalZone ? translation.totalMessage : translation.insideMessage)}</span>
        </div>
      </div>
      {!isTotalZone && (
        <div className="zone-details">
          <div className={classNames("icon-container", "waiting-time-icon-container")}>
            <FontAwesomeIcon className="icon" icon={faClock} />
          </div>
          <div className="container">
            <span className="label">
              {waitingTime ? (
                <>
                  {!!hours && (
                    <>
                      {hours}
                      <span>h </span>
                    </>
                  )}
                  {!!minutes && (
                    <>
                      {minutes}
                      <span> {i18n.t(translation.minutes)} </span>
                    </>
                  )}
                  {!!seconds && !hours && (
                    <>
                      {seconds}
                      <span> s </span>
                    </>
                  )}
                </>
              ) : (
                "-"
              )}
            </span>
            <span className="description">{i18n.t(translation.waitingTimeMessage)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const HomeWrapper: FunctionComponent = () => {
  const token = useSelector((state: RootState) => state.auth.token);

  if (!token) return null;

  return (
    <div className="home-page-wrapper">
      <img className="logo" src={Logo} alt="Logo" />
      <ZoneComponent token={token} zoneId={1913} name={"Total"} isTotalZone={true} />
      <div className="container">
        <ZoneComponent token={token} zoneId={1911} name={"La Pointe Chateau"} isInLeftColumn={true} />
        <ZoneComponent token={token} zoneId={1912} name={"La Pointe Caffareli"} />
      </div>
      <div className="footer">
        <span className="footer-text">{i18n.t(translation.poweredBy)}</span>
        <img className="footer-logo" src={TechnisLogo} alt="Technis" />
      </div>
    </div>
  );
};
