import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import React, { useEffect, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { apollo } from "./api/apollo";
import { persistor, store } from "./redux/store";
import { ThemeContextProvider } from "./components/common/Theme";
import { BrowserRouter } from "react-router-dom";
import { MainRoutes } from "./components/routing/MainRoutes";
import { login } from "./redux/auth/auth.actions";

require("../styles/index.scss");

const { initClient } = apollo;

const configFirebase = {
  apiKey: "AIzaSyAc6wjbPlSzXV5zc9KX3NnYNENbLjFrlN8",
  authDomain: "technis-counting.firebaseapp.com",
  databaseURL: "https://technis-counting.firebaseio.com",
  storageBucket: "technis-counting.appspot.com",
  appId: "1:159429876253:web:1b4bf2b89308a5446544f4",
  projectId: "technis-counting",
};
export const firebaseApp = initializeApp(configFirebase);
export const firebaseDb = getDatabase(firebaseApp);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [subdomain] = window.location.hostname.split(".");
  useEffect(() => {
    initClient()
      // TODO : remove this
      .then(login(subdomain === "localhost" ? "lapointe" : subdomain))
      .then(() => setIsLoading(false))
      .catch((e: Error) => {
        console.log(e);
        setIsLoading(false);
      });
  }, []);

  return !isLoading ? (
    <ApolloProvider client={apollo.client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeContextProvider setRoot>
            <BrowserRouter>
              <MainRoutes />
            </BrowserRouter>
          </ThemeContextProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  ) : null;
};

render(<App />, document.getElementById("root"));
