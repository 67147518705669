import { AnyAction, applyMiddleware, createStore, Reducer } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage";
import { default as ReduxThunk } from "redux-thunk";

import { DEFAULT_STATE as initialAuthState, reducer as auth } from "./auth/auth.reducer";
import { DEFAULT_STATE as initialRouterState, reducer as router } from "./router/router.reducer";

export const initialRootState = {
  auth: initialAuthState,
  router: initialRouterState,
};

const rootReducer = {
  auth,
  router,
};

const config = {
  key: "root",
  storage,
  transforms: [createWhitelistFilter("auth", []), createWhitelistFilter("modals", []), createWhitelistFilter("router", [])],
};

// @ts-ignore
const reducer: Reducer<RootState, AnyAction> = persistCombineReducers(config, rootReducer);

export type RootState = typeof initialRootState;

const configureStore = () => {
  const store = createStore(reducer, initialRootState, applyMiddleware(ReduxThunk));
  const persistor = persistStore(store);
  return { store, persistor };
};

export const { store, persistor } = configureStore();
