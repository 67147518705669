import { ID } from "@technis-counting/shared";
import * as H from "history";

import { RouterAction, RouterActions } from "./router.actions";

export type RouterHistory = H.History;

export type RouterParams = {
  organizationId?: ID;
  eventId?: ID;
  zoneId?: ID;
};

export interface RouterState {
  history?: RouterHistory;
  path?: string;
  params?: RouterParams;
}

/** The default state of the routerReducer. */
export const DEFAULT_STATE: RouterState = {
  path: "",
};

export const reducer = (state: RouterState = DEFAULT_STATE, action: RouterAction): RouterState => {
  switch (action.type) {
    case RouterActions.UPDATE_ROUTER:
      return { ...state, history: action.history, path: action.path, params: action.params };
    case RouterActions.RESET_ROUTER:
      return { ...DEFAULT_STATE };
    default:
      return state;
  }
};
