import { ID, Maybe } from "@technis-counting/shared";
import { off, onValue, ref } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from ".";
import { firebaseDb } from "../index";

const getZoneUrl = (zoneId: ID) => `${process.env.FIREBASE_DATABASE_ROOT}/liveKPIs/682/1318/zones/${zoneId}`;

type FirebaseData = {
  INSIDE: number;
  WAITING_TIME: number;
  AFFLUENCE_IN: number;
};

const DISCONNECT_CHECK_INTERVAL = 59999; // Every 59 seconds

export const useWaitingTime = (token: string | undefined, topZoneId: ID) => {
  const [inside, setInside] = useState<Maybe<number>>(null);
  const [waitingTime, setWaitingTime] = useState<Maybe<number>>(null);
  const [affluenceIn, setAffluenceIn] = useState<Maybe<number>>(null);

  const isConnectedRef = useRef(false);

  const url = getZoneUrl(topZoneId || 0);

  // Firebase listeners
  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);
    console.log("Subscribed to ", url);

    onValue(
      countRef,
      (snapshot) => {
        const val: Maybe<FirebaseData> = snapshot.val();

        setInside(val?.INSIDE);
        setWaitingTime(val?.WAITING_TIME);
        setAffluenceIn(val?.AFFLUENCE_IN);
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return {
    inside,
    waitingTime,
    affluenceIn,
  };
};
